import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RedButtonFixedSize from './RedButtonFixedSize';
import DropdownArrow from '../icons/dropdownArrow.svg';


const { useState } = React;

const Container = styled.div`
@media(min-width:993px){
    margin-bottom:50px;
}
`

const TitleContainer = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:90%;
max-width:275px;
margin:0 auto;

& > h3 {
    font-weight:600;
    font-size:18px;
    font-family:Lato;
}

& svg{
    height:30px;
    width:30px;
    transform:rotate(${props => props.expanded==1?('180deg'):('0')});
    transition:transform 0.4s ease;
    &:hover{
        cursor:pointer;
    }
}

@media(min-width:601px){
    max-width:none;
    width:560px;

    & > h3 {
        font-size:20px;
    }
}

@media(min-width:993px){
    width:845px;

    & svg{
        display:none;
    }
}

@media(min-width:1200px){
    width:1130px;
}
`

const ListContainer = styled.div`
display:${props => props.expanded==1?('block'):('none')};
width:90%;
max-width:275px;
margin:0 auto;

@media(min-width:600px){
    max-width:none;
    width:560px;
}

@media(min-width:993px){
    display:block;
    width:845px;
}

@media(min-width:1200px){
    width:1130px;
}
`

const List = styled.div`
display:flex;
flex-flow:row wrap;
gap:10px;
`

const ToggleListContainer = ({expanded,data}) => {
    const [isExpanded, toggleIsExpanded] = useState(expanded);

    const toggleExpansion = () => (
        toggleIsExpanded(!isExpanded)
    );

    return(
        <Container>
            <TitleContainer expanded={isExpanded?(1):(0)}>
                <h3>{data.title}</h3>
                <DropdownArrow onClick={toggleExpansion}/>
            </TitleContainer>
            <ListContainer expanded={isExpanded?(1):(0)}>
                {
                    Array.isArray(data.list) ?
                    <List>
                    {
                    data.list.map((item, i) => (
                        <RedButtonFixedSize key={i} id={i} href={item.link}>{item.text}</RedButtonFixedSize>
                    ))
                    }
                    </List> : <div></div>
                }
               
            </ListContainer>
        </Container>
    )
};

ToggleListContainer.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        list: PropTypes.shape({
            text: PropTypes.string,
            link: PropTypes.string,
        }),
    }),
};

export default ToggleListContainer;