import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown'

const Container = styled.div`
width:90%;
max-width:275px;
margin:0 auto;
margin-top:100px;

& p {
    font-family:Lato;
    font-size:16px;
    text-align:justify;
}

@media(min-width:993px){
    max-width:600px;
}
`

const FeuerwehrenText = ({data}) => (
    <Container>
        <ReactMarkdown>{data}</ReactMarkdown>
    </Container>
);

FeuerwehrenText.propTypes = {
    data: PropTypes.string,
};

export default FeuerwehrenText;