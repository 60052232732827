import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RedButtonFixedSize from './RedButtonFixedSize';
import ToggleListContainer from './ToggleListContainer';


const Container = styled.div`
font-size:16x;

& > h2 {
    text-align:center;
    font-family:Montserrat;
    font-size:24px;
    font-weight:700;
    margin:0;
}

@media(min-width:601px){
    & > h2 {
        font-size:28px,
    }
}
`

const FeuerwehrenListe = ({data}) => (
    <Container>
        <h2>{data.title}</h2>
        {
            data.gemeinden.map((item, i) => {
                if(i==0)
                    return(
                        <ToggleListContainer data={item} expanded={true}/>
                    )
                return (<ToggleListContainer data={item} expanded={false}/>)
            })
        }
    </Container>
);

FeuerwehrenListe.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        gemeinden: PropTypes.arrayOf(PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({
                text: PropTypes.string,
                link: PropTypes.string,
            })),
        })),
    }),
};

export default FeuerwehrenListe;