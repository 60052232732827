import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Loader } from "@googlemaps/js-api-loader"
import TestIcon from '../icons/dropdownArrow.svg'

const { useRef, useEffect } = React

const Container = styled.div`
position:relative;
display:block;
width:100%;
min-height:min(600px,80vh);
height:80vh;
`

const loader = new Loader({
    apiKey: "AIzaSyChcva-F73Yl9l-lTE1ubtoKKhxcopwSqw",
    version: "weekly",
    libraries: ["places"],
});

const Map = ({data}) => {

    const mapRef = useRef(null)

    // const betzdorfPosition = { lat: 50.7837576, lng: 7.8762327 }
    // const freusburgPosition = { lat: 50.8277802, lng: 7.8799189 }
    // const kirchenPosition = { lat: 50.8114937, lng: 7.8911552 }
    // const wissenPosition = { lat: 50.7813134, lng: 7.7258913 }
    // const demoContainer = [["Freiwillige Feuerwehr Betzdorf", "Hier befindet sich die freiwillige Feuerwehr Betzdorf.",betzdorfPosition],
    //                 ["Freiwillige Feuerwehr Kirchen", "Hier befindet sich die freiwillige Feuerwehr Kirchen.", kirchenPosition],
    //                 ["Freiwillige Feuerwehr Freusburg", "Hier befindet sich die freiwillige Feuerwehr Freusburg.", freusburgPosition],
    //                 ["Freiwillige Feuerwehr Wissen", "Hier befindet sich die freiwillige Feuerwehr Wissen.", wissenPosition]]

    useEffect(() => {
        loader.load().then((google) => {
            const map = new google.maps.Map(mapRef.current, {
              center: { lat: 50.755156, lng: 7.7481655 },
              zoom: 9.25,
              disableDefaultUI: true,
            });

            data.map((item,i) => {
                const content = "<div><h3>" + item.name + "</h3><img src=''/><p>" + item.text + "</p></div>"
                const marker = new google.maps.Marker({
                    position: { lat: item.lat, lng: item.lon },
                    map: map,
                    title: item.name,
                })
                const infowindow = new google.maps.InfoWindow({
                    content: content,
                })
                marker.addListener('click', () => {
                    infowindow.open({
                        anchor: marker,
                        map: map,
                    })
                    map.panTo(item.name)
                    map.setZoom(13)
                })
                map.addListener('click', () => {
                    infowindow.close()
                })
            })
        });
    }, []);

    return(
        <Container ref={mapRef}/>
    )
}

Map.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        lat: PropTypes.string,
        lon: PropTypes.string,
        name: PropTypes.string,
        text: PropTypes.string,
    })),
}

export default Map