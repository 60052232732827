import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FeuerwehrenPageTemplate } from '../templates/feuerwehren-template'

const FeuerwehrenPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <FeuerwehrenPageTemplate
      feuerwehrenKarte = { frontmatter.feuerwehrenKarte }
      descriptiveText = { frontmatter.descriptiveText }
      overview = { frontmatter.overview }
    />
  )
}

FeuerwehrenPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default FeuerwehrenPage

export const feuerwehrenPageQuery = graphql`
query FeuerwehrenTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "feuerwehren-page"}}) {
    frontmatter {
      
      feuerwehrenKarte {
        lat        
        lon        
        name        
        text        
      }
      descriptiveText      
      overview {
        title        
        gemeinden {
          title          
          list {
            text            
            link            
          }
        }
      }
    }
  }
}
`