import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import FeuerwehrenListe from '../components/FeuerwehrenListe';
import FeuerwehrenText from '../components/FeuerwehrenText';
import Layout from '../components/Layout';
import Map from '../components/Map';
import Spacer from '../components/Spacer';


export const FeuerwehrenPageTemplate = ({
  feuerwehrenKarte,
  descriptiveText,
  overview,
}) => (
    <div>
        <Helmet title="Feuerwehren" defer={false}/>
        <Layout activePageIndex={5}>
          <Map data={ feuerwehrenKarte }/>
          <Spacer height={125}/>
          <FeuerwehrenListe data={ overview }/>
          <Spacer height={50}/>
        </Layout>
    </div>
);

FeuerwehrenPageTemplate.propTypes = {
  feuerwehrenKarte: PropTypes.array,
  descriptiveText: PropTypes.object,
  overview: PropTypes.object,
}