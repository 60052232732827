import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Arrows from '../icons/arrows.svg'

const Container = styled.a`
width:90%;
max-width:275px;
background-color:var(--tmain);
border: 2px solid var(--tmain);
border-radius:8px;
color:var(--twhite);
padding:0px 10px 2px 10px;
text-decoration:none;


font-family:Montserrat;
font-weight:700;
font-style:italic;
font-size:16px;
word-wrap:break-word;

&::before {
    content:"»";
    color:var(--twhite);
    font-size:22px;
    margin-right:8px;
}

&:hover{
    color:var(--tmain);
    background-color:var(--twhite);
    cursor:pointer;

    &::before {
        color:var(--tmain);
    }
}

@media(min-width:993px){
    &::before {
        content:"»";
        color:var(--twhite);
        font-size:22px;
        margin-right:10px;
    }

    padding:0px 10px 2px 10px;
    font-size:16px;
}
`

const RedButton = ({href, children}) => (
    <Container href={href}>{children}</Container>
);

RedButton.propTypes = {
};

export default RedButton;